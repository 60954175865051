import React from "react";
import LogoApp from "../assets/logo-app.png";

export const LoginPage = (props: { network: "offline" | "online" }) => {
    return (
        <div className="ui login">
            <h1>Create your own ice cream cup</h1>
            <img src={LogoApp} />

            {props.network == "offline" && (
                <h2>Before using the app you have to log in but you are currently offline</h2>
            )}
            {props.network == "online" && (
                <a href="/login" className="green-link">
                    Login
                </a>
            )}
        </div>
    );
};
