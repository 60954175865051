import imglyRemoveBackground, { Config, preload } from "@imgly/background-removal";

// Result of a clip operation
//
// This interface contains two images (both in png format):
// - the `original` one which is resized to not exceed the maximum allowed size
// - the `clipped` one where the background is removed
//
// If an error occurred during the clip operation, the `clipped` attribute is
// undefined.
export interface ImageReady {
    original: Blob;
    clipped: Blob | null;
}

const config: Config = {
    publicPath: location.origin + "/dist/@imgly/",
    model: "small",
    // debug: true,
};

export async function preloadAssetsForLocalClipping() {
    return preload(config);
}

export async function warmupLocalClippingCode() {
    const canvas = new OffscreenCanvas(1, 1);
    canvas.getContext("2d");
    const image = await canvas.convertToBlob();
    return imglyRemoveBackground(image, config);
}

async function resize(image: Blob, width: number): Promise<Blob> {
    const bmp = await createImageBitmap(image, { resizeWidth: width });

    const canvas = new OffscreenCanvas(bmp.width, bmp.height);
    const ctx = canvas.getContext("bitmaprenderer")!;
    ctx.transferFromImageBitmap(bmp);

    return canvas.convertToBlob({ type: "image/png" });
}

async function netClip(image: Blob): Promise<Blob | null> {
    const form = new FormData();
    form.append("image", image, "image");

    const response = await fetch("/api/clip", { method: "POST", body: form });
    if (!response.ok) return null;

    return response.blob();
}

async function localClip(image: Blob): Promise<Blob | null> {
    return imglyRemoveBackground(image, config).catch((e) => {
        console.log("[W] clip failed:", e);
        return null;
    });
}

export async function clipImage(image: Blob, mode: "online" | "offline"): Promise<ImageReady> {
    let t0 = new Date();
    const original = await resize(image, 512);
    console.log("[W] image resized:", new Date().valueOf() - t0.valueOf());

    let clipped: Blob | null = null;
    if (mode == "online") {
        t0 = new Date();
        try {
            clipped = await netClip(original);
            console.log("[W] image clipped (online):", new Date().valueOf() - t0.valueOf());
        } catch (e) {
            console.log("[W] online clipping failed:", e!.toString());
        }
    }

    if (mode == "offline" || !clipped) {
        t0 = new Date();
        clipped = await localClip(original);
        console.log("[W] image clipped (local):", new Date().valueOf() - t0.valueOf());
    }

    if (!clipped) return { original, clipped: null };

    return {
        original,
        clipped,
    };
}
