import React, { useState } from "react";
import { AppStatus } from "../js_api/messages";
import { LoginPage } from "./login";
import { SelectPhoto } from "./select_photo";
import { Preview3dModel } from "./preview_model";
import { CustomerForm } from "./customer_form";
import { AccountPage } from "./account_page";
import { FinalWords } from "./final_words";
import { UploadQueue } from "./app/upload_queue";

export const MainPage = (props: { queue: UploadQueue; app: AppStatus }) => {
    const [logoUrl, setLogoUrl] = useState(""); //http://localhost:8000/dist/x.png");
    const [modelRender, setModelRender] = useState<Blob | null>(null);
    const [modelAccepted, setModelAccepted] = useState(false);
    const [showFinalWords, setShowFinalWords] = useState(false);
    const [showAccountPage, setShowAccountPage] = useState(false);

    const onRestart = () => {
        setLogoUrl("");
        setShowAccountPage(false);
        setShowFinalWords(false);
        setModelRender(null);
        setModelAccepted(false);
    };

    const onProjectComplete = (customer: string, email: string) => {
        props.queue.enqueueProject(customer, email, logoUrl, modelRender);
        setShowFinalWords(true);
    };

    const onAccountPage = () => {
        setShowAccountPage(true);
    };

    const onCloseAccountPage = () => {
        setShowAccountPage(false);
    };

    const onModelAccepted = (cupRendering: Blob | null) => {
        setModelRender(cupRendering);
        setModelAccepted(true);
    };

    if (props.app.user == null) {
        return <LoginPage network={props.app.network} />;
    }

    let Page: React.ReactNode;
    if (showAccountPage) Page = <AccountPage queue={props.queue} app={props.app} onClose={onCloseAccountPage} />;
    else if (showFinalWords) Page = <FinalWords onRestart={onRestart} onAccountPage={onAccountPage} />;
    else {
        if (logoUrl == "")
            Page = <SelectPhoto network={props.app.network} onNextStep={setLogoUrl} onAccountPage={onAccountPage} />;
        else if (modelAccepted == false)
            Page = (
                <Preview3dModel
                    logoUrl={logoUrl}
                    onRestart={onRestart}
                    onNextStep={onModelAccepted}
                    onAccountPage={onAccountPage}
                />
            );
        else
            Page = (
                <CustomerForm
                    logoUrl={logoUrl}
                    onRestart={onRestart}
                    onNextStep={onProjectComplete}
                    onAccountPage={onAccountPage}
                />
            );
    }

    return Page;
};
