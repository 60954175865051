const texSize = 1024;
// const rangeY = [408, 615];
const rangeY = [0, 208];

export async function generateCheckerTexture(): Promise<Blob> {
    const canvas = new OffscreenCanvas(texSize, texSize);
    const ctx = canvas.getContext("2d");
    if (!ctx) return new Blob();

    let color = 0;
    for (let y = rangeY[0]; y <= rangeY[1]; y += 10) {
        for (let x = 0; x < texSize; x += 10) {
            ctx.fillStyle = color % 2 == 0 ? "black" : "white";
            ctx.fillRect(x, y, 10, 10);
            color += 1;
        }
    }

    return canvas.convertToBlob();
}

export interface TextureConfig {
    img: ImageBitmap;
    imgBackground: string | ImageBitmap;
    imgScaleFactor: number; // [0, 1]
    imgRep: 2 | 4;
    imgRot: number; // radians
}

export async function generateCupTexture(cfg: TextureConfig): Promise<Blob> {
    const canvas = new OffscreenCanvas(texSize, texSize);
    const ctx = canvas.getContext("2d");
    if (!ctx) return new Blob();

    if (typeof cfg.imgBackground == "string") {
        ctx.fillStyle = cfg.imgBackground;
        ctx.fillRect(0, 0, texSize, texSize);
    } else {
        ctx.drawImage(cfg.imgBackground, 0, 0, texSize, texSize);
    }

    ctx.translate(0, texSize);
    ctx.scale(1, -1);

    const { img, imgScaleFactor, imgRep, imgRot } = cfg;

    // pixels available for each repetitions
    const repWidth = Math.floor(texSize / 4);
    const repHeight = rangeY[1] - rangeY[0];
    const horz = img.width >= img.height;

    let nSize = [0, 0];
    if (horz) {
        nSize = [repWidth, repHeight * (img.height / img.width)];
    } else {
        nSize = [repWidth * (img.width / img.height), repHeight];
    }
    nSize[0] *= imgScaleFactor;
    nSize[1] *= imgScaleFactor;

    const offX = (repWidth - nSize[0]) / 2;
    const offY = (repHeight - nSize[1]) / 2;

    for (let i = 0; i < 4; i++) {
        if (imgRep == 2 && i % 2 != 0) continue;

        ctx.save();

        const x = i * repWidth + offX;
        const y = rangeY[0] + offY;
        ctx.translate(x + nSize[0] / 2, y + nSize[1] / 2);
        ctx.rotate(imgRot);
        ctx.translate(-x - nSize[0] / 2, -y - nSize[1] / 2);
        ctx.drawImage(img, x, y, nSize[0], nSize[1]);

        ctx.restore();
    }
    return canvas.convertToBlob();
}

export async function drawTexture(canvas: HTMLCanvasElement, tex: Blob) {
    canvas.width = texSize;
    canvas.height = texSize;
    const ctx = canvas.getContext("2d")!;
    const img = await createImageBitmap(tex);
    ctx.drawImage(img, 0, 0);
}
