import { createRoot } from "react-dom/client";
import { renderUI } from "./ui";
import { UploadQueue } from "./app/upload_queue";

// url of the service worker
declare const SWSource = "";

const root = document.getElementById("root");
if (root) {
    if ("serviceWorker" in navigator) {
        installApp(root, SWSource);
    } else {
        root.innerText = "unsupported browser";
    }
}

async function registerServiceWorker(swSource: string): Promise<void> {
    console.log("registering sw", swSource);

    await navigator.serviceWorker.register(swSource, { scope: "/" });
    await navigator.serviceWorker.ready;
}

async function installApp(root: HTMLElement, swSource: string) {
    console.log("initializing app");
    try {
        await registerServiceWorker(swSource);
    } catch (error) {
        root.innerText = "app error";
        console.error("service worker registration failed:", error);
        return;
    }

    console.log("initializing upload queue");
    const queue = new UploadQueue();

    const start = () => {
        console.log("starting app", navigator.serviceWorker.controller);
        const uiRoot = createRoot(root);
        renderUI(uiRoot, queue);
    };

    start();
}
