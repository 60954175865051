interface QueueElement {
    customer: string;
    email: string;
    image: string;
    render: string | null;
}

const blobToDataURL = (blob: Blob): Promise<string> => {
    return new Promise<string>((resolve) => {
        const fr = new FileReader();
        fr.addEventListener("load", () => {
            resolve(fr.result as string);
        });
        fr.readAsDataURL(blob);
    });
};

export class UploadQueue {
    private items: QueueElement[];

    constructor() {
        this.items = this.loadQueue();

        const sendOne = async () => {
            await this.sendProject();
            setTimeout(sendOne, 1000);
        };
        setTimeout(sendOne, 1000);
    }

    async enqueueProject(customer: string, email: string, imageUrl: string, render: Blob | null): Promise<void> {
        const response = await fetch(imageUrl);
        if (!response.ok) throw new Error("error reading url");

        const imgBlob = await response.blob();
        const imgDataURL = await blobToDataURL(imgBlob);
        const renderDataURL = render ? await blobToDataURL(render) : null;

        const items = [...this.items];
        items.push({
            customer,
            email,
            image: imgDataURL,
            render: renderDataURL,
        });
        this.saveQueue(items);
        this.items = items;

        console.log("new project enqueued");
    }

    public size() {
        return this.items.length;
    }

    private async sendProject(): Promise<boolean> {
        if (this.items.length == 0) return true;

        const item = this.items[0];
        console.log("sending", item.customer);

        const imgBlob = await (await fetch(item.image)).blob();
        const renderBlob = item.render ? await (await fetch(item.render)).blob() : null;

        const form = new FormData();
        form.append("customer", item.customer);
        form.append("email", item.email);
        form.append("image", imgBlob, "image");
        if (renderBlob) form.append("render", renderBlob, "render");

        try {
            const response = await fetch("/api/project", { method: "POST", body: form });
            if (!response.ok) return false;
        } catch (e) {
            console.log("cannot send the project", e);
            return false;
        }

        const items = [...this.items];
        items.shift();
        this.saveQueue(items);
        this.items = items;

        return true;
    }

    private loadQueue(): QueueElement[] {
        const raw = localStorage.getItem("projectsQueue") || "[]";
        try {
            return JSON.parse(raw);
        } catch (e) {
            console.log("cannot deserialize the project queue", e);
            return [];
        }
    }

    private saveQueue(items: QueueElement[]) {
        try {
            const raw = JSON.stringify(items);
            localStorage.setItem("projectsQueue", raw);
        } catch (e) {
            console.log("cannot serialize the project queue", e);
            return;
        }
    }
}
