import React, { useState } from "react";
import { Root } from "react-dom/client";
import { AppStatus, getAppStatus } from "./app/status";
import { MainPage } from "./main_page";
import { preloadAssetsForLocalClipping } from "./app/clip";

import "./style.css";
import { UploadQueue } from "./app/upload_queue";

const Ui = (props: { queue: UploadQueue }) => {
    const [appStatus, setAppStatus] = useState<AppStatus>();

    React.useEffect(() => {
        getAppStatus()
            .then(setAppStatus)
            .then(() => {
                document.addEventListener("online", () => setAppStatus({ ...appStatus!, network: "online" }));
                document.addEventListener("offline", () => setAppStatus({ ...appStatus!, network: "offline" }));
            });

        preloadAssetsForLocalClipping();
    }, []);

    return appStatus && <MainPage app={appStatus} queue={props.queue} />;
};

export const renderUI = (root: Root, queue: UploadQueue) => {
    root.render(<Ui queue={queue} />);
};
