import React from "react";
import { Point, imageBBox, imageBitmapToImageData, imageDataToBlob, maskImage, maskStrokedImage } from "./img_utils";
import RgbQuant from "rgbquant";

interface IDrawableCanvas {
    background: Blob;
    // edit: boolean;
    // called when the user ends to edit the image;
    onRegionSelected: (img: Blob) => void;
}

export const DrawableCanvas = (props: IDrawableCanvas) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    // a copy of the last props.background to make it visible to the event handlers
    const background = React.useRef<ImageBitmap>();
    // when true the user events had entered the drawing mode
    const drawing = React.useRef<boolean>(false);
    // the current path drawn by the user
    const path = React.useRef<Point[]>([]);
    // current canvas zoom factor
    const zoomFactor = React.useRef<number>(1);
    // current canvas translation
    const translate = React.useRef<[number, number]>([0, 0]);
    // last mouse position (in canvas coordinate) used to translate the canvas when using the mouse
    const lastMousePos = React.useRef<[number, number] | null>(null);
    // last touches used to zoom and translate the canvas when using a multi-touch device
    const touches = React.useRef<{ identifier: number; clientX: number; clientY: number }[]>([]);

    const clearCanvas = (canvas: HTMLCanvasElement) => {
        const img = background.current;
        if (!img) return;

        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        ctx.scale(zoomFactor.current, zoomFactor.current);
        ctx.translate(translate.current[0], translate.current[1]);
        ctx.drawImage(img, 0, 0);
    };

    React.useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // mouse position in image coordinate (x,y);
        const mousePos = (evt: { clientX: number; clientY: number }): [number, number] => {
            // change the mouse position taking into account the scale applied
            // by the layout engine to the canvas size
            const rect = canvas.getBoundingClientRect();
            const offsetX = evt.clientX - rect.left;
            const offsetY = evt.clientY - rect.top;

            const x = (offsetX * canvas.width) / rect.width;
            const y = (offsetY * canvas.height) / rect.height;

            // transform the mouse position taking in account the canvas
            // transformation matrix
            //
            // goot to read: https://roblouie.com/article/617/transforming-mouse-coordinates-to-canvas-coordinates/
            const pt = ctx.getTransform().invertSelf().transformPoint(new DOMPoint(x, y));
            return [pt.x, pt.y];
        };

        const dist = (a: { clientX: number; clientY: number }, b: { clientX: number; clientY: number }) => {
            const dx = a.clientX - b.clientX;
            const dy = a.clientY - b.clientY;
            return Math.sqrt(dx * dx + dy * dy);
        };

        const copyTouch = (v: { identifier: number; clientX: number; clientY: number }) => {
            return {
                identifier: v.identifier,
                clientX: v.clientX,
                clientY: v.clientY,
            };
        };

        const start = (evt: { clientX: number; clientY: number }) => {
            drawing.current = true;

            clearCanvas(canvas);

            const [x, y] = mousePos(evt);
            path.current = [new Point(x, y)];
            ctx.moveTo(x, y);
        };

        const cancel = () => {
            drawing.current = false;
            touches.current = [];
            path.current = [];
            clearCanvas(canvas);
        };

        const trackMove = (evt: { clientX: number; clientY: number }) => {
            if (!drawing.current) return;

            const [x, y] = mousePos(evt);
            const last = path.current.slice(-1)[0];
            const d = Math.sqrt((last.x - x) * (last.x - x) + (last.y - y) * (last.y - y));
            if (d < 10) return;

            path.current.push(new Point(x, y));

            ctx.strokeStyle = "#027f48";
            ctx.lineWidth = 8;
            ctx.lineTo(x, y);
            ctx.stroke();
        };

        const end = () => {
            drawing.current = false;

            if (!background.current) return;

            const points = path.current;
            if (points.length == 0) return;

            ctx.beginPath();
            ctx.moveTo(points[0].x, points[0].y);
            for (let ix = 1; ix < points.length; ix++) {
                ctx.lineTo(points[ix].x, points[ix].y);
            }

            ctx.fillStyle = "rgba(255, 0, 0, 0.3)";
            ctx.fill("evenodd");

            // const output = maskImage(background.current, points);
            // if (output == null) return;

            const img = imageBitmapToImageData(background.current);
            if (img == null) return;

            const result = postProcessImage(img, points);
            if (result == null) return;

            createImageBitmap(result).then((bmp) => {
                background.current = bmp;
                clearCanvas(canvas);

                imageDataToBlob(result).then((x) => {
                    if (x) props.onRegionSelected(x);
                });
            });
        };

        const zoomCanvas = (factor: number) => {
            zoomFactor.current = Math.min(Math.max(zoomFactor.current * factor, 0.5), 3);

            clearCanvas(canvas);

            ctx.resetTransform();
            ctx.scale(zoomFactor.current, zoomFactor.current);
            ctx.translate(translate.current[0], translate.current[1]);

            path.current = [];
        };

        const translateCanvas = (dx: number, dy: number) => {
            translate.current[0] += dx;
            translate.current[1] += dy;

            clearCanvas(canvas);

            ctx.resetTransform();
            ctx.scale(zoomFactor.current, zoomFactor.current);
            ctx.translate(translate.current[0], translate.current[1]);

            path.current = [];

            // if we want to draw the path while moving the canvas...
            //
            // const points = path.current;
            // if (points.length > 0) {
            //     ctx.beginPath();
            //     ctx.moveTo(points[0].x, points[0].y);
            //     for (let ix = 1; ix < points.length; ix++) {
            //         ctx.lineTo(points[ix].x, points[ix].y);
            //     }
            //     ctx.strokeStyle = "#027f48";
            //     ctx.lineWidth = 8;
            //     ctx.stroke();
            // }
        };

        // The touch events are used to zoom, translate and drawing on
        // the canvas while using a multi-touch device.
        canvas.addEventListener("touchstart", (evt: TouchEvent) => {
            evt.preventDefault();

            cancel();
            if (evt.touches.length == 1) {
                start(evt.touches[0]);
            } else {
                touches.current = [copyTouch(evt.touches[0]), copyTouch(evt.touches[1])];
            }
        });

        canvas.addEventListener("touchmove", (evt: TouchEvent) => {
            evt.preventDefault();
            if (evt.touches.length == 1) {
                trackMove(evt.touches[0]);
            } else {
                const d1 = dist(touches.current[0], touches.current[1]);
                const d2 = dist(evt.touches[0], evt.touches[1]);

                const isPinch = Math.abs(d1 - d2) > 0.2;
                if (isPinch) {
                    zoomCanvas(d2 / d1);
                } else {
                    // isTranslate
                    const dx = (evt.touches[0].clientX - touches.current[0].clientX) * 2;
                    const dy = (evt.touches[0].clientY - touches.current[0].clientY) * 2;
                    translateCanvas(dx, dy);
                }

                touches.current = [copyTouch(evt.touches[0]), copyTouch(evt.touches[1])];
            }
        });

        canvas.addEventListener("touchend", (evt: TouchEvent) => {
            evt.preventDefault();
            if (evt.touches.length == 0) {
                end();
            } else {
                cancel();
            }
        });

        canvas.addEventListener("touchcancel", cancel);

        // The wheel + mouse events are used to zoom, translate and drawing on
        // the canvas while using a mouse.
        canvas.addEventListener("wheel", (evt: WheelEvent) => {
            evt.preventDefault();
            if (evt.shiftKey) {
                zoomCanvas(evt.deltaY > 0 ? 1.1 : 0.9);
            }
        });

        canvas.addEventListener("mousedown", start);
        canvas.addEventListener("mousemove", (evt: MouseEvent) => {
            evt.preventDefault();
            if (evt.shiftKey && evt.buttons == 1) {
                const pt = mousePos(evt);
                if (lastMousePos.current) {
                    const [x, y] = lastMousePos.current;
                    translateCanvas(pt[0] - x, pt[1] - y);
                }
                lastMousePos.current = pt;
            } else {
                trackMove(evt);
            }
        });
        canvas.addEventListener("mouseup", (evt: MouseEvent) => {
            evt.preventDefault();
            lastMousePos.current = null;
            end();
        });
    }, []);

    const resetBackground = async (file: Blob): Promise<void> => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const img = imageBitmapToImageData(await createImageBitmap(file));
        background.current = await createImageBitmap(img || new ImageData(1, 1));
        clearCanvas(canvas);
    };

    React.useEffect(() => {
        resetBackground(props.background);
    }, [props.background]);

    return <canvas ref={canvasRef} />;
};

function postProcessImage(original: ImageData, points: Point[]): ImageData | null {
    const masked = maskImage(original, points);
    if (!masked) return null;

    const stroked = maskStrokedImage(original, points, 2);
    if (!stroked) return null;

    const [tl, br] = imageBBox(masked);
    let q = new RgbQuant({ colors: 16 });
    q.sample(stroked);
    // palette sorted by frequency (highest to lowest)
    const pal = q.palette(true, true);

    q = new RgbQuant({ colors: 16 });
    q.sample(masked);
    const pixels = q.reduce(masked);

    const result = new ImageData(new Uint8ClampedArray(pixels), masked.width, masked.height);
    const fc = pal[0];
    for (let y = tl.y; y <= br.y; y++) {
        for (let x = tl.x; x <= br.x; x++) {
            const i = y * result.width * 4 + x * 4;
            if (result.data[i + 3] == 0) {
                result.data[i + 0] = fc[0];
                result.data[i + 1] = fc[1];
                result.data[i + 2] = fc[2];
                result.data[i + 3] = 255;
            }
        }
    }

    return result;
}
