import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { EffectComposer } from "three/addons/postprocessing/EffectComposer.js";
import { RenderPass } from "three/addons/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/addons/postprocessing/UnrealBloomPass.js";
import { OutputPass } from "three/addons/postprocessing/OutputPass.js";
import { ShaderPass } from "three/addons/postprocessing/ShaderPass.js";
import { ObjectControls } from "threejs-object-controls";
import { drawTexture, generateCupTexture } from "./texture";
import classNames from "classnames";
import { loadImageBitmap } from "./img_utils";
import { Arrow } from "./arrow";

import cupModelUrl from "../assets/cup3.glb";
import cupBlurriedPinkBg from "../assets/cup3_blurried_pink_bg.jpg";
import cupTextureBase from "../assets/cup3_texture_base.jpg";
import Logo from "../assets/logo.png";
import Star from "../assets/star.svg";
import Direction from "../assets/direction.svg";
import RotateLeft from "../assets/rotate_left.svg";
import RotateRight from "../assets/rotate_right.svg";

interface ThreeCtx {
    scene: THREE.Scene;
    camera: THREE.PerspectiveCamera;
    renderer: THREE.WebGLRenderer;
    bloomComposer: EffectComposer;
    finalComposer: EffectComposer;
}

function findObject(obj: THREE.Object3D, name: string): THREE.Object3D | null {
    if (obj.name == name) return obj as THREE.Mesh;

    if (obj.children && Array.isArray(obj.children)) {
        for (const el of obj.children) {
            const m = findObject(el, name);
            if (m) return m;
        }
    }

    return null;
}

// a promise with the 3d model; we start loading it before the component is
// mounted
const cupModelPromise = ((): Promise<[THREE.Scene, THREE.Camera[]]> => {
    const loader = new GLTFLoader();
    return new Promise((resolve) => {
        loader.load(
            cupModelUrl,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (gltf: any) => {
                resolve([gltf.scene as THREE.Scene, gltf.cameras as THREE.Camera[]]);
            },
        );
    });
})();

// a promise with the image we use as the background for our texture; this
// image is not a white background but has some grainy "texture"
const cupTextureBaseBg = (async (): Promise<ImageBitmap> => {
    const res = await fetch(cupTextureBase);
    const data = await res.blob();
    return createImageBitmap(data);
})();

// a promise with the texture for the bg of the cup; we start loading it before
// the component is mounted
const cupTexturePinkBgPromise = ((): Promise<THREE.Texture> => {
    return new Promise((resolve) => {
        new THREE.TextureLoader().load(cupBlurriedPinkBg, (texture) => {
            texture.colorSpace = THREE.SRGBColorSpace;
            // the uv in the model are flipped (at least flipped according to
            // threejs)
            texture.flipY = false;
            resolve(texture);
        });
    });
})();

// subset of the `generateCupTexture` accepted parameters that can be changed
// by the user
interface TexGenerationParams {
    imgScaleFactor: number; // [0, 1]
    imgRep: 2 | 4;
    imgRot: number;
    imgBackground: string | ImageBitmap;
}

interface IPreview3dModel {
    // the image to apply on the 3d model
    logoUrl: string;
    // called when the user wants to abort the current project and start again
    onRestart: () => void;
    // called when the user wants to go to the next step; the argument is a
    // rendering of the 3d model
    onNextStep: (cupRendering: Blob | null) => void;
    // called when the user wants to open the account page
    onAccountPage: () => void;
}

// the dark color material used to "switch off" the bloom filter on the cup
// model, so that it stands out from the background
const darkMaterial = new THREE.MeshBasicMaterial({ color: "black" });

// construct the render pipeline; the pipeline is composed by a bloom filter
// mixed up with the original scene.
//
// The returned value is a tuple with the bloom composer and the final composer
const renderPipeline = (params: {
    scene: THREE.Scene;
    camera: THREE.Camera;
    renderer: THREE.WebGLRenderer;
    viewWidth: number;
    viewHeight: number;
}): [EffectComposer, EffectComposer] => {
    const { scene, camera, renderer, viewWidth, viewHeight } = params;
    const renderScene = new RenderPass(scene, camera);

    // see: https://threejs.org/examples/?q=bloom#webgl_postprocessing_unreal_bloom_selective
    const bloomPass = new UnrealBloomPass(new THREE.Vector2(viewWidth, viewHeight), 1.5, 0.4, 0.85);
    bloomPass.threshold = 0;
    bloomPass.strength = 0.8;
    bloomPass.radius = 0;

    const bloomComposer = new EffectComposer(renderer);
    bloomComposer.renderToScreen = false;
    bloomComposer.addPass(renderScene);
    bloomComposer.addPass(bloomPass);
    bloomComposer.setSize(viewWidth, viewHeight);

    const mixPass = new ShaderPass(
        new THREE.ShaderMaterial({
            uniforms: {
                baseTexture: { value: null },
                bloomTexture: { value: bloomComposer.renderTarget2.texture },
            },
            vertexShader: `
                varying vec2 vUv;
                void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }
            `,
            fragmentShader: `
                uniform sampler2D baseTexture;
                uniform sampler2D bloomTexture;

                varying vec2 vUv;

                void main() {
                    // The color from the scene without the bloom filter
                    // applied. This is transparent everywhere except where the
                    // cup is.
                    vec4 color = texture2D( baseTexture, vUv );

                    // The output color, we mix the bloom texture everywhere
                    // except where the cup is because we want it to stand out.
                    gl_FragColor = ( color + vec4( 1.0 - color.w ) * texture2D( bloomTexture, vUv ));
                }
            `,
            defines: {},
        }),
        "baseTexture",
    );
    mixPass.needsSwap = true;

    // Use a multisampled render target to avoid aliasing artifacts
    const target = new THREE.WebGLRenderTarget(viewWidth, viewHeight, {
        samples: 8,
        type: THREE.HalfFloatType,
    });

    const finalComposer = new EffectComposer(renderer, target);
    finalComposer.addPass(renderScene);
    finalComposer.addPass(mixPass);
    finalComposer.addPass(new OutputPass());
    finalComposer.setSize(viewWidth, viewHeight);

    return [bloomComposer, finalComposer];
};

// apply a texture to the 3d model
const applyTexture = async (blob: Blob, obj: THREE.Mesh, showDebug: boolean) => {
    if (showDebug) {
        const c = document.getElementById("debug_texture") as HTMLCanvasElement;
        drawTexture(c, blob);
    }

    return new Promise<void>((resolve) => {
        new THREE.TextureLoader().load(URL.createObjectURL(blob), (texture) => {
            texture.colorSpace = THREE.SRGBColorSpace;
            const mat = new THREE.MeshStandardMaterial({
                name: "Mat_texture",
                map: texture,
            });
            obj.material = mat;
            resolve();
        });
    });
};

// ensure the object is not too big; if the object width is greater than the
// viewport size * fraction it is scaled down
const ensureMaxObjectSize = (camera: THREE.PerspectiveCamera, obj: THREE.Mesh, fraction: number): boolean => {
    obj.geometry.computeBoundingBox();
    const bbox = obj.geometry.boundingBox!;
    const c = new THREE.Vector3();
    bbox.getCenter(c);
    const dist = camera.position.distanceTo(c);

    // calculate the viewport in world units
    const vFOV = THREE.MathUtils.degToRad(camera.fov); // convert vertical fov to radians
    const viewHeight = 2 * Math.tan(vFOV / 2) * dist; // visible height
    const viewWidth = viewHeight * camera.aspect; // visible width

    const mesh_width = bbox.max.x - bbox.min.x;
    if (mesh_width <= viewWidth * fraction) return false;

    const s = (viewWidth * fraction) / mesh_width;
    obj.scale.set(s, s, s);
    obj.updateMatrix();
    return true;
};

export const Preview3dModel = (props: IPreview3dModel) => {
    const view3d = useRef<HTMLDivElement>(null);
    const view3dInitialized = useRef(false);

    // the request animation id; used to cancel the queuest call once this
    // component is unmounted
    const renderLoop = useRef(0);

    // timestamp of the last rendered frame; used to slow down the FPS and
    // don't burn too much CPU
    const lastFrame = useRef(0);

    const [texParams, setTexParams] = useState<TexGenerationParams>({
        imgScaleFactor: 0.9,
        imgRep: 4,
        imgRot: 0,
        imgBackground: "#ffffff",
    });

    // The 3d scene, rendered and camera created when the component is mounted
    const ctx3d = useRef<ThreeCtx | null>(null);

    // the logo passed in the props; loading started as soon as possible (it is
    // not expected to change once the component is mounted)
    const logoBitmapPromise = useRef<Promise<ImageBitmap | null>>(loadImageBitmap(props.logoUrl));

    const logoBitmap = useRef<ImageBitmap | null>(null);

    // The THREE Mesh with the cup model
    const cupObject = useRef<THREE.Mesh | null>(null);

    // A rendering of the 3d scene taken just after the initialization; the
    // null value indicates an error trying to render the scene
    //
    // This rendering is then attached to the feedback email sent to the
    // customer.
    const cupRender = useRef<Blob | null | undefined>(undefined);

    // window.localStorage.setItem("debug_canvas", "1") and reload the page
    const showDebugCanvas = useRef<boolean>(localStorage.getItem("debug_texture") == "1");

    const [showControls, setShowControls] = useState(false);

    const renderView = (ts: number) => {
        if (ts - lastFrame.current >= 30) {
            if (ctx3d.current && cupObject.current) {
                const { bloomComposer, finalComposer, scene } = ctx3d.current;

                // We render the scene twice as normal with the bloom filter;
                // the first one (bloomComposer) is needed to apply the bloom
                // filter on a texture and the second one (finalComposer) mix
                // the first texture with the scene.

                // Since we need for the cup to stand out from the background
                // we can't use the bloom filter pipeline as demoed on the
                // threejs website, because otherwise the cup would be blurred
                // by the bloom filter.
                //
                // So in the first pass we render the full scene which will be
                // drawn to a texture; next we render the scene again but
                // switching off the background so that the second texture will
                // be transparent everywhere except where the cup is.
                //
                // At this point the shader which mixes the two textures will
                // use the bloom texture only where the last texture is
                // transparent.
                scene.traverse((obj) => {
                    if (obj.name == "BG") obj.visible = true;
                });

                // The darkMaterial is the canonical way to switch off (or
                // better tone down) the bloom filter; since we render only the
                // cup during the second pass one can argue that we don't need
                // to change the cup material.
                //
                // In reality we need it because otherwise the final image will
                // have a white halo around the cup (halo which became darker
                // thanks to the darkMaterial).
                const m = cupObject.current.material;
                cupObject.current.material = darkMaterial;
                bloomComposer.render();

                // Disable the background and set the cup material back to the
                // original.
                scene.traverse((obj) => {
                    if (obj.name == "BG") obj.visible = false;
                });
                cupObject.current.material = m;
                finalComposer.render();
            }
        }
        renderLoop.current = requestAnimationFrame(renderView);
    };

    useEffect(() => {
        if (view3d.current && !view3dInitialized.current) {
            view3dInitialized.current = true;

            const view = view3d.current;

            const renderer = new THREE.WebGLRenderer({
                preserveDrawingBuffer: true,
                // The alpha channel is necessary because our shader pass use
                // it to determine which texture to use
                alpha: true,
            });

            renderer.outputColorSpace = THREE.SRGBColorSpace;
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(view.clientWidth, view.clientHeight);
            renderer.toneMapping = THREE.ReinhardToneMapping;
            renderer.toneMappingExposure = Math.pow(1, 4);

            view.appendChild(renderer.domElement);

            Promise.all([cupModelPromise, cupTextureBaseBg, cupTexturePinkBgPromise, logoBitmapPromise.current!])
                .then(([[scene, cameras], baseTexture, bgTexture, logo]) => {
                    // The scene is loaded and will be rendered at the next
                    // frame; now it's time to setup the camera and apply the
                    // correct texture to the bg object.
                    //
                    // All steps are optional, meaning they can fail without
                    // blocking the rendering of the component; this makes the
                    // life easier for everyone since the artist can change the
                    // scene and in case of error the user can still see the 3d
                    // model.
                    let camera: THREE.PerspectiveCamera | null = null;
                    const gltfCamera = cameras.find((c) => c.name == "Camera001");
                    if (gltfCamera) {
                        // this is the camera from the GLTF file; I will use
                        // the one setup by the artist but change the aspect
                        // ratio because it depends on the device viewport
                        camera = gltfCamera as THREE.PerspectiveCamera;
                        camera.aspect = view.clientWidth / view.clientHeight;
                        camera.updateProjectionMatrix();
                    } else {
                        camera = new THREE.PerspectiveCamera(75, view.clientWidth / view.clientHeight, 0.1, 1000);
                    }

                    // build the render pipeline
                    const [bloomComposer, finalComposer] = renderPipeline({
                        scene,
                        camera,
                        renderer,
                        viewWidth: view.clientWidth,
                        viewHeight: view.clientHeight,
                    });

                    ctx3d.current = {
                        scene,
                        camera,
                        renderer,
                        bloomComposer,
                        finalComposer,
                    };

                    const bg = findObject(scene, "BG") as THREE.Mesh | null;
                    if (bg) {
                        bg.material = new THREE.MeshBasicMaterial({
                            map: bgTexture,
                        });
                    }

                    texParams.imgBackground = baseTexture;

                    logoBitmap.current = logo;

                    cupObject.current = findObject(scene, "Coppetta_142001") as THREE.Mesh | null;
                })
                .then(async () => {
                    // make the compiler happy
                    if (!ctx3d.current || !cupObject.current || !logoBitmap.current) return;

                    const { camera } = ctx3d.current;

                    // scale the object if it's too big
                    ensureMaxObjectSize(camera, cupObject.current, 0.8);

                    // enable the user controls
                    const controls = new ObjectControls(camera, renderer.domElement, cupObject.current);
                    controls.enableVerticalRotation();
                    controls.enableVerticalRotation();

                    // apply the texture to the cup object
                    return applyTexture(
                        await generateCupTexture({ img: logoBitmap.current, ...texParams }),
                        cupObject.current,
                        showDebugCanvas.current,
                    );
                })
                .then(() => {
                    setTimeout(() => {
                        renderer.domElement.toBlob(
                            (file) => {
                                console.log("scene caputered");
                                cupRender.current = file;
                            },
                            "image/jpg",
                            0.75,
                        );
                    }, 300);
                });

            renderLoop.current = requestAnimationFrame(renderView);
        }

        return () => {
            cancelAnimationFrame(renderLoop.current);
            if (view3d.current) view3d.current.innerHTML = "";
            view3dInitialized.current = false;
        };
    }, [view3d]);

    const onRestartClicked = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        props.onRestart();
    };

    const onNextClicked = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        // user clicked too fast
        if (cupRender.current == undefined) return;
        props.onNextStep(cupRender.current);
    };

    const onParamChange = async (params: TexGenerationParams) => {
        setTexParams(params);

        if (!cupObject.current || !logoBitmap.current) return;
        return applyTexture(
            await generateCupTexture({ img: logoBitmap.current, ...params }),
            cupObject.current,
            showDebugCanvas.current,
        );
    };

    return (
        <div className="ui preview-model">
            <div className="top-bar">
                <div className="logo">
                    <img src={Logo} />
                </div>

                <span className="filler" />

                <button onClick={onRestartClicked}>
                    <Arrow dir="left" />
                    Abort project
                </button>

                <div dangerouslySetInnerHTML={{ __html: Star }} />
            </div>

            <div className="work-area">
                <div className="view3d" ref={view3d} />
                <div className={classNames("controls", { open: showControls })}>
                    <div
                        className="toggler"
                        onClick={() => setShowControls(!showControls)}
                        dangerouslySetInnerHTML={{ __html: Direction }}
                    ></div>
                    <TextureControls params={texParams} onParamChange={onParamChange} />
                </div>
                {showDebugCanvas.current && <canvas id="debug_texture" />}
            </div>

            <div className="bottom-bar primary">
                <span className="filler" />
                <button onClick={onNextClicked}>
                    Next step
                    <Arrow dir="right" />
                </button>
            </div>
        </div>
    );
};

interface ITextureControls {
    params: TexGenerationParams;
    // called when the user change a parameter
    onParamChange: (params: TexGenerationParams) => void;
}

const TextureControls = (props: ITextureControls) => {
    // every rotStep is +90deg
    const [rotSteps, setRotSteps] = useState(0);
    const [rotOffset, setRotOffset] = useState(0);

    const onScaleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const n = Number(evt.target.value);
        if (isNaN(n)) return;

        props.onParamChange({
            ...props.params,
            imgScaleFactor: n / 100,
        });
    };

    const onRepsChange = (evt: React.MouseEvent<HTMLDivElement>) => {
        const d = (evt.target as HTMLDivElement).dataset;
        if (!d) return;

        let n = Number(d.value);
        if (isNaN(n)) return;

        if (n != 2 && n != 4) n = 4;

        props.onParamChange({
            ...props.params,
            imgRep: n as 2 | 4,
        });
    };

    const onRotChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const n = Number(evt.target.value);
        if (isNaN(n)) return;

        const deg = rotSteps * 90 + n;
        setRotOffset(n);
        props.onParamChange({
            ...props.params,
            imgRot: (deg * Math.PI) / 180,
        });
    };

    const onBkgChange = (evt: React.MouseEvent<HTMLDivElement>) => {
        const d = (evt.target as HTMLDivElement).dataset;
        if (!d || !d.value) return;

        props.onParamChange({
            ...props.params,
            imgBackground: d.value,
        });
    };

    const onRotStepChange = (evt: React.MouseEvent<HTMLSpanElement>) => {
        let el = evt.target as HTMLElement;
        if (el.nodeName == "svg") el = el.parentNode! as unknown as HTMLElement;

        const d = el.dataset;
        if (!d || !d.value) return;

        const n = rotSteps + Number(d.value);
        const deg = n * 90 + rotOffset;
        setRotSteps(n);
        props.onParamChange({
            ...props.params,
            imgRot: (deg * Math.PI) / 180,
        });
    };

    const { imgRep, imgBackground, imgScaleFactor } = props.params;

    return (
        <div className="texture-parameters">
            <div className="inline">
                <label>Repetitions</label>
                <div className="static-list" onClick={onRepsChange}>
                    <div data-value="2" className={classNames({ selected: imgRep == 2 })}>
                        2
                    </div>
                    <div data-value="4" className={classNames({ selected: imgRep == 4 })}>
                        4
                    </div>
                </div>
            </div>
            <div className="inline">
                <label>Background</label>
                <div className="static-list background" onClick={onBkgChange}>
                    {/* eslint-disable prettier/prettier */}
                    <div data-name="PAP70" data-value="#ffe700" className={classNames({ selected: imgBackground == "#ffe700" })} style={{ backgroundColor: "#ffe700" }} />
                    <div data-name="PAP80" data-value="#0092cc" className={classNames({ selected: imgBackground == "#0092cc" })} style={{ backgroundColor: "#0092cc" }} />
                    <div data-name="PAP100" data-value="#68b32d" className={classNames({ selected: imgBackground == "#68b32d" })} style={{ backgroundColor: "#68b32d" }} />
                    <div data-name="PAP120" data-value="#e73352" className={classNames({ selected: imgBackground == "#e73352" })} style={{ backgroundColor: "#e73352" }} />
                    <div data-name="PAP140" data-value="#f59e4c" className={classNames({ selected: imgBackground == "#f59e4c" })} style={{ backgroundColor: "#f59e4c" }} />
                    <div data-name="PAP160" data-value="#ed8399" className={classNames({ selected: imgBackground == "#ed8399" })} style={{ backgroundColor: "#ed8399" }} />
                    <div data-name="PAP190" data-value="#c9d658" className={classNames({ selected: imgBackground == "#c9d658" })} style={{ backgroundColor: "#c9d658" }} />
                    <div data-name="PAP259" data-value="#f8cb6b" className={classNames({ selected: imgBackground == "#f8cb6b" })} style={{ backgroundColor: "#f8cb6b" }} />
                    <div data-name="PAP290" data-value="#8bc5a1" className={classNames({ selected: imgBackground == "#8bc5a1" })} style={{ backgroundColor: "#8bc5a1" }} />
                    <div data-name="PAP450" data-value="#b69e63" className={classNames({ selected: imgBackground == "#b69e63" })} style={{ backgroundColor: "#b69e63" }} />
                    <div data-name="PAP550" data-value="#74bfc3" className={classNames({ selected: imgBackground == "#74bfc3" })} style={{ backgroundColor: "#74bfc3" }} />
                    <div data-name="" data-value="#ffffff" className={classNames({ selected: imgBackground == "#ffffff" })} style={{ backgroundColor: "#ffffff" }} />
                    {/* eslint-enbale prettier/prettier */}
                </div>
            </div>
            <div>
                <label>Size</label>
                <input
                    type="range"
                    min="20"
                    max="95"
                    step="any"
                    value={imgScaleFactor * 100}
                    onChange={onScaleChange}
                />
            </div>
            <div className="rotation">
                <label>
                    <span>Rotation</span>
                    <span
                        data-value="-1"
                        onClick={onRotStepChange}
                        dangerouslySetInnerHTML={{ __html: RotateLeft }}
                    ></span>
                    <span
                        data-value="1"
                        onClick={onRotStepChange}
                        dangerouslySetInnerHTML={{ __html: RotateRight }}
                    ></span>
                </label>
                <input
                    type="range"
                    min="-20"
                    max="20"
                    step="any"
                    value={rotOffset}
                    onChange={onRotChange}
                />
            </div>
        </div>
    );
};
