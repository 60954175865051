import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { clipImage } from "./app/clip";

import PhotoCamera from "../assets/photo_camera.svg";
import SelectMedia from "../assets/select_media.svg";
import Logo from "../assets/logo.png";
import Star from "../assets/star.svg";
import LogoApp from "../assets/logo-app.png";
import Edit from "../assets/edit.svg";
import { DrawableCanvas } from "./drawable_canvas";
import { Arrow } from "./arrow";

async function startVideoPreview(video: HTMLVideoElement): Promise<void> {
    const stream = await navigator.mediaDevices.getUserMedia({
        video: {
            facingMode: { ideal: "environment" },
        },
        audio: false,
    });
    video.srcObject = stream;
    video.play();
}

export const SelectPhoto = (props: {
    network: "online" | "offline";
    onNextStep: (imageDataUrl: string) => void;
    onAccountPage: () => void;
}) => {
    // the user-selected source for the preview image
    const [selectedSrc, setSelectedSrc] = useState<"camera" | "select-media" | null>(null);

    // A reference to the video element used by the "camera" src to show the
    // live stream from the user camera
    const videoRef = useRef<HTMLVideoElement>(null);
    // A reference to the input used to select a file on the local system.
    const localFileRef = useRef<HTMLInputElement>(null);

    // A flag set to true when the new selected image is under processing for
    // clipping and background removal
    const [processingImage, setProcessingImage] = useState(false);
    // The image selected by the user
    const [originalImg, setOriginalImg] = useState<Blob | null>(null);
    // The original image once edited by the user (if done)
    const [editedImg, setEditedImg] = useState<Blob | null>(null);
    // The edited image after the clipping
    const [editTempClippedImg, setEditTempClippedImg] = useState<Blob | null>(null);
    // The image once clipped (post processed)
    const [clippedImg, setClippedImg] = useState<Blob | null>(null);
    // The image to show in the preview element
    const [previewImgUrl, setPreviewImgUrl] = useState<string>("");
    // show the warning text if the clip failed
    const [clipWarning, setClipWarning] = useState(false);
    // set to true when the user enter in edit mode
    const [editMode, setEditMode] = useState(false);
    // Flag to protect us from "double processing" the same image
    const imageProcessed = useRef(false);

    const cameraAvailable = !!navigator.mediaDevices;

    // To quick test the edit mode
    //
    // useEffect(() => {
    //     fetch("http://localhost:8000/dist/x.png")
    //         .then((response) => response.blob())
    //         .then((blob) => {
    //             setOriginalImg(blob);
    //             setEditMode(true);
    //         });
    // }, []);

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        // DOM ready
        video.addEventListener("canplay", () => {
            // videoWidth and videHeight are the size of the camera,
            // regardless how the video element is resized
            const w = video.videoWidth;
            const h = video.videoHeight;

            // it's ok to set thw element width and heigth to a value
            // bigger than the available space; the CSS rules will take
            // care to set a max size
            video.width = w;
            video.height = h;

            if (video.offsetWidth < w) {
                // the DOM element was resized due to the CSS rules
                const r = video.offsetWidth / w;
                if (r < 0.95) {
                    // if the DOM element is resized, the media stream is
                    // centered vertically in the available space; we apply
                    // a transform to move it under the toolbar
                    const unusedSpace = video.offsetHeight - r * h;
                    video.style.transform = `translateY(${-unusedSpace / 2}px)`;
                }
            }
        });

        video.addEventListener("click", () => onTakeAPhoto());
    }, [videoRef]);

    const reset = () => {
        setClippedImg(null);
        setEditedImg(null);
        setOriginalImg(null);
        setPreviewImgUrl("");
        setClipWarning(false);
        setSelectedSrc(null);
        imageProcessed.current = false;
    };

    const onSrcSelected = (src: "camera" | "select-media") => {
        if (src == "camera" && !videoRef.current) return;
        if (src == "select-media" && !localFileRef.current) return;

        reset();
        setSelectedSrc(src);

        switch (src) {
            case "camera": {
                startVideoPreview(videoRef.current!);
                break;
            }
            case "select-media": {
                selectLocalFile(localFileRef.current!);
                break;
            }
        }
    };

    const performClipping = async (image: Blob): Promise<Blob> => {
        setProcessingImage(true);
        setClipWarning(false);

        try {
            const result = await clipImage(image, props.network);
            if (!result.clipped) {
                setClipWarning(true);
            }

            // if the clip process failed we reuse the original image
            return result.clipped || image;
        } finally {
            setProcessingImage(false);
        }
    };

    const selectLocalFile = (el: HTMLInputElement) => {
        el.onchange = (ev: Event) => {
            const files = (ev as unknown as React.ChangeEvent<HTMLInputElement>).currentTarget.files;
            if (!files) return;

            setOriginalImg(files[0]);
            setPreviewImgUrl(URL.createObjectURL(files[0]));
            setSelectedSrc(null);

            performClipping(files[0]).then((result) => {
                setClippedImg(result);
                setPreviewImgUrl(URL.createObjectURL(result));
            });
        };

        el.click();
    };

    const onTakeAPhoto = async () => {
        const video = videoRef.current;
        if (!video) return;

        const canvas = new OffscreenCanvas(video.videoWidth, video.videoHeight);
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        const blob = await canvas.convertToBlob();

        video.srcObject = null;

        setOriginalImg(blob);
        setPreviewImgUrl(URL.createObjectURL(blob));
        setSelectedSrc(null);

        const result = await performClipping(blob);
        setClippedImg(result);
        setPreviewImgUrl(URL.createObjectURL(result));
    };

    const onRestartClicked = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        reset();
    };

    const onNextClicked = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        if (!originalImg) return;

        const url = URL.createObjectURL(clippedImg || editedImg || originalImg);
        props.onNextStep(url);
    };

    const onStartEditing = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setEditedImg(null);
        setEditMode(true);
    };

    // called when the user select a region of the original image
    const onRegionSelected = async (file: Blob) => {
        setPreviewImgUrl(URL.createObjectURL(file));
        setEditedImg(file);

        const result = await performClipping(file);
        setEditTempClippedImg(result);
        setPreviewImgUrl(URL.createObjectURL(result));
    };

    // called when the user wants to cancel the current edit
    const onCancelEdit = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();

        setEditMode(false);
        setEditedImg(null);
        setEditTempClippedImg(null);
        setPreviewImgUrl(clippedImg ? URL.createObjectURL(clippedImg) : "");
    };

    // called when the user accepts to use the current edit
    const onAcceptEdit = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();

        setEditMode(false);
        setClippedImg(editTempClippedImg);
        setEditTempClippedImg(null);
    };

    // called when the user wants to try the edit once again
    const onEditAgain = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();

        setEditedImg(null);
        setEditTempClippedImg(null);
    };

    return (
        <div className={classNames("ui", "select-photo", selectedSrc)}>
            <div className="top-bar">
                <div className="logo">
                    <img src={Logo} />
                </div>

                <span className="filler" />

                <div dangerouslySetInnerHTML={{ __html: Star }} onClick={() => props.onAccountPage()} />
            </div>

            <div className={classNames("work-area", editMode ? "edit" : "")}>
                <div className="image-panel">
                    {selectedSrc == null && originalImg == null && (
                        <div className="intro-text">
                            <img src={LogoApp} />
                            <h1>Create your own ice cream cup</h1>
                            <h1>LET&apos;S START</h1>
                        </div>
                    )}

                    <div className={classNames("preview-camera", { active: selectedSrc == "camera" })}>
                        <p>Tap to acquire image</p>
                        <video className={classNames({ active: selectedSrc == "camera" })} ref={videoRef} playsInline />
                    </div>

                    <div className="preview-select-media">
                        <input type="file" accept="image/*" ref={localFileRef} />
                    </div>

                    {previewImgUrl != "" && (
                        <>
                            {!processingImage && (
                                <div
                                    className="edit-icon"
                                    onClick={onStartEditing}
                                    dangerouslySetInnerHTML={{ __html: Edit }}
                                ></div>
                            )}
                            <PreviewImage url={previewImgUrl} processing={processingImage} warning={clipWarning} />
                        </>
                    )}
                </div>
                <div className="edit-panel">
                    {originalImg && editedImg == null && (
                        <DrawableCanvas background={originalImg} onRegionSelected={onRegionSelected} />
                    )}

                    {originalImg && editedImg != null && (
                        <PreviewImage url={previewImgUrl} processing={processingImage} warning={clipWarning} />
                    )}
                </div>
            </div>

            <div className="bottom-bar">
                {editMode == false && clippedImg == null && (
                    <>
                        <div
                            className={classNames("select-media", { selected: selectedSrc == "select-media" })}
                            onClick={() => onSrcSelected("select-media")}
                            dangerouslySetInnerHTML={{ __html: SelectMedia }}
                        ></div>

                        {cameraAvailable && (
                            <div
                                className={classNames("camera", { selected: selectedSrc == "camera" })}
                                onClick={() => onSrcSelected("camera")}
                                dangerouslySetInnerHTML={{ __html: PhotoCamera }}
                            ></div>
                        )}
                    </>
                )}

                {editMode == false && clippedImg != null && (
                    <>
                        <button onClick={onRestartClicked}>
                            <Arrow dir="left" />
                            Delete
                        </button>

                        <span className="filler" />

                        <button onClick={onNextClicked}>
                            Next step
                            <Arrow dir="right" />
                        </button>
                    </>
                )}

                {editMode == true && (
                    <>
                        <button onClick={onCancelEdit}>Cancel</button>

                        {editTempClippedImg != null && <button onClick={onEditAgain}>Try again</button>}

                        {editTempClippedImg != null && <button onClick={onAcceptEdit}>Continue</button>}
                    </>
                )}
            </div>
        </div>
    );
};

const PreviewImage = (props: { url: string; processing: boolean; warning: boolean }) => {
    const { url, processing, warning } = props;
    return (
        <div className={classNames("preview-selected-image", { processing })}>
            {warning && <p className="warning">An error occurred during the background removal on your device.</p>}
            <img src={url} />
            <div className="spinner lds-dual-ring" />
        </div>
    );
};
