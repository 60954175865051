import React from "react";
import { AppStatus } from "../js_api/messages";
import Logo from "../assets/logo.png";
import Close from "../assets/close.svg";
import classNames from "classnames";
import { UploadQueue } from "./app/upload_queue";

export const AccountPage = (props: { queue: UploadQueue; app: AppStatus; onClose: () => void }) => {
    return (
        <div className="ui account">
            <div className="top-bar">
                <div className="logo">
                    <img src={Logo} />
                </div>

                <span className="filler" />

                <div dangerouslySetInnerHTML={{ __html: Close }} onClick={() => props.onClose()} />
            </div>
            <div className="work-area">
                <p>Welcome</p>

                <h1>{props.app.user}</h1>

                <p className="app-info">
                    <span>App version:</span>
                    <span>{props.app.version}</span>
                    <span>Status:</span>
                    <span className={classNames("circle", props.app.network)} />
                    <span>{props.app.network}</span>
                </p>

                {props.queue.size() == 1 && (
                    <p className="queue-size">One project will be uploaded once you will be online again</p>
                )}

                {props.queue.size() > 1 && (
                    <p className="queue-size">
                        {props.queue.size()} projects will be uploaded once you will be online again
                    </p>
                )}
            </div>
        </div>
    );
};
