/*
export interface Rect {
    x: number;
    y: number;
    width: number;
    height: number;
}

export function drawRectOnImg(r: Rect, img: HTMLImageElement): string {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) return "";

    ctx.drawImage(img, 0, 0);
    ctx.strokeStyle = "#00ff00";
    ctx.strokeRect(r.x, r.y, r.width, r.height);

    return canvas.toDataURL("image/png");
}

export async function imgToBlob(img: HTMLImageElement): Promise<Blob | null> {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    ctx.drawImage(img, 0, 0);

    return new Promise<Blob | null>((resolve) => {
        canvas.toBlob(resolve);
    });
}

export function getImageData(img: HTMLImageElement): ImageData | null {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    ctx.drawImage(img, 0, 0);
    return ctx.getImageData(0, 0, img.width, img.height);
}

export function getImageData2(img: HTMLImageElement): string | null {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL("image/png");
}
*/

export async function loadImageBlob(url: string): Promise<Blob | null> {
    const r = await fetch(url);
    if (!r.ok) {
        console.log("cannot fetch the image");
        return null;
    }

    try {
        return await r.blob();
    } catch (e) {
        console.log("cannot convert image to bitmap");
        return null;
    }
}

export async function loadImageBitmap(url: string): Promise<ImageBitmap | null> {
    const blob = await loadImageBlob(url);
    if (blob == null) return null;

    return await createImageBitmap(blob);
}

export async function loadImageData(url: string): Promise<ImageData | null> {
    const bmp = await loadImageBitmap(url);
    if (bmp == null) return null;

    const canvas = new OffscreenCanvas(bmp.width, bmp.height);
    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    ctx.drawImage(bmp, 0, 0);

    return ctx.getImageData(0, 0, canvas.width, canvas.height);
}

export function imageBitmapToImageData(bmp: ImageBitmap): ImageData | null {
    const canvas = new OffscreenCanvas(bmp.width, bmp.height);
    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    ctx.drawImage(bmp, 0, 0);

    return ctx.getImageData(0, 0, canvas.width, canvas.height);
}

export async function imageDataToBlob(img: ImageData): Promise<Blob | null> {
    const canvas = new OffscreenCanvas(img.width, img.height);
    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    ctx.putImageData(img, 0, 0);

    return canvas.convertToBlob();
}

export class Point {
    constructor(
        public x: number,
        public y: number,
    ) { }
}

/* extracts the portion of `img` contained by `path` */
export function maskImage(img: ImageData, path: Point[]): ImageData | null {
    if (path.length == 0) return null;

    const w = img.width;
    const h = img.height;

    const canvas = new OffscreenCanvas(w, h);
    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    ctx.beginPath();
    ctx.moveTo(path[0].x, path[0].y);
    for (let ix = 1; ix < path.length; ix++) {
        ctx.lineTo(path[ix].x, path[ix].y);
    }

    ctx.fillStyle = "red";
    ctx.fill("evenodd");

    const mask = ctx.getImageData(0, 0, w, h);

    ctx.fillStyle = "rgb(0,0,0,0)";
    ctx.fillRect(0, 0, w, h);

    const copy = ctx.getImageData(0, 0, w, h);

    for (let y = 0; y < h; y++) {
        for (let x = 0; x < w; x++) {
            const i = y * w * 4 + x * 4;
            if (mask.data[i] != 0) {
                copy.data[i + 0] = img.data[i + 0];
                copy.data[i + 1] = img.data[i + 1];
                copy.data[i + 2] = img.data[i + 2];
                copy.data[i + 3] = img.data[i + 3];
            }
        }
    }

    return copy;
}

/* extracts the portion of `img` contained by `path` */
export function maskStrokedImage(img: ImageData, path: Point[], lineWidth: number): ImageData | null {
    if (path.length == 0) return null;

    const w = img.width;
    const h = img.height;

    const canvas = new OffscreenCanvas(w, h);
    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    ctx.beginPath();
    ctx.moveTo(path[0].x, path[0].y);
    for (let ix = 1; ix < path.length; ix++) {
        ctx.lineTo(path[ix].x, path[ix].y);
    }

    ctx.strokeStyle = "red";
    ctx.lineWidth = lineWidth;
    ctx.stroke();

    const mask = ctx.getImageData(0, 0, w, h);

    ctx.fillStyle = "rgb(0,0,0,0)";
    ctx.fillRect(0, 0, w, h);

    const copy = ctx.getImageData(0, 0, w, h);

    for (let y = 0; y < h; y++) {
        for (let x = 0; x < w; x++) {
            const i = y * w * 4 + x * 4;
            if (mask.data[i] != 0) {
                copy.data[i + 0] = img.data[i + 0];
                copy.data[i + 1] = img.data[i + 1];
                copy.data[i + 2] = img.data[i + 2];
                copy.data[i + 3] = img.data[i + 3];
            }
        }
    }

    return copy;
}

export function imageBBox(img: ImageData): [Point, Point] {
    const w = img.width;
    const h = img.height;

    const tl = new Point(w, h);
    const br = new Point(0, 0);

    for (let y = 0; y < h; y++) {
        for (let x = 0; x < w; x++) {
            const i = y * w * 4 + x * 4;
            if (img.data[i + 3] != 0) {
                if (y < tl.y) tl.y = y;
                if (x < tl.x) tl.x = x;
                if (y > br.y) br.y = y;
                if (x > br.x) br.x = x;
            }
        }
    }

    return [tl, br];
}

export function cropImage(img: ImageData, sx: number, sy: number, sw: number, sh: number): ImageData | null {
    const w = img.width;
    const h = img.height;

    const canvas = new OffscreenCanvas(w, h);
    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    ctx.putImageData(img, 0, 0);

    return ctx.getImageData(sx, sy, sw, sh);
}
