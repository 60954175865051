import React from "react";
import Logo from "../assets/logo.png";
import Star from "../assets/star.svg";

export const FinalWords = (props: { onRestart: () => void; onAccountPage: () => void }) => {
    const onRestartClicked = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        props.onRestart();
    };

    return (
        <div className="ui final-words">
            <div className="top-bar">
                <div className="logo">
                    <img src={Logo} />
                </div>

                <span className="filler" />

                <div dangerouslySetInnerHTML={{ __html: Star }} onClick={() => props.onAccountPage()} />
            </div>
            <div className="work-area">
                <h1>Well done!</h1>

                <a href="#" className="green-link" onClick={onRestartClicked}>
                    Make another one
                </a>
            </div>
        </div>
    );
};
