import React, { useState } from "react";
import Logo from "../assets/logo.png";
import Star from "../assets/star.svg";
import { Arrow } from "./arrow";
import classNames from "classnames";

export const CustomerForm = (props: {
    logoUrl: string;
    onRestart: () => void;
    onNextStep: (customer: string, email: string) => void;
    onAccountPage: () => void;
}) => {
    const [customer, setCustomer] = useState("");
    const [email, setEmail] = useState("");

    const onRestartClicked = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        props.onRestart();
    };

    const onNextClicked = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        props.onNextStep(customer, email);
    };

    return (
        <div className="ui customer-form">
            <div className="top-bar">
                <div className="logo">
                    <img src={Logo} />
                </div>

                <span className="filler" />

                <button onClick={onRestartClicked}>
                    <Arrow dir="left" />
                    Abort project
                </button>

                <div dangerouslySetInnerHTML={{ __html: Star }} onClick={() => props.onAccountPage()} />
            </div>

            <div className="work-area">
                <form>
                    <div>
                        <input
                            id="customer_name"
                            type="text"
                            tabIndex={0}
                            autoFocus={true}
                            placeholder="Customer"
                            onChange={(e) => setCustomer(e.target.value)}
                            value={customer}
                        />
                    </div>

                    <div>
                        <input
                            id="customer_email"
                            type="text"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>

                    <div>
                        <button
                            className={classNames({ disabled: customer == "" || email == "" })}
                            onClick={onNextClicked}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
