export interface AppStatus {
    // application version
    version: string;
    network: "online" | "offline";
    // if user attribute is null there is no user logged in
    user: string | null;
}

// return the application status by trying to connect with the remote server
export async function getAppStatus(): Promise<AppStatus> {
    let version = "";
    const swc = navigator.serviceWorker;
    if (swc.controller) {
        version = await new Promise((resolve) => {
            const listener = (ev: MessageEvent) => {
                if (ev.data.type == "version") {
                    swc.removeEventListener("message", listener);
                    resolve(ev.data.version);
                }
            };
            swc.addEventListener("message", listener);
            swc.controller!.postMessage({ type: "version" });

            // if we don't recevie a response from an in-process worker in
            // 100ms probably there is a bug somewhere
            setTimeout(() => resolve("?"), 100);
        });
    }

    try {
        const r = await fetch("/api/ping");
        if (!r.ok) {
            // our api cannot return a non-OK response; probably this is the
            // response formwarded by a middleman
            return {
                version,
                network: "offline",
                user: null,
            };
        }

        const data = await r.json();
        return {
            version,
            network: "online",
            user: data.user,
        };
    } catch (e) {
        console.log("network error", e);
        return {
            version,
            network: "offline",
            user: null,
        };
    }
}
